<template>
  <div class="container" style="margin-top: 80px">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-md-auto mb-3">
        <span>目前第 {{ currentPage }} 頁 / 總共 {{ totalPages }} 頁</span>
      </div>

      <div class="col-12 col-md-auto mb-3">
        <b-pagination
          class="my-auto"
          v-if="totalRows > 0"
          v-model="localCurrentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          @change="onChangePage"
        ></b-pagination>
      </div>

      <div class="col-12 col-md-auto mb-3">
        <span>每頁顯示數量：</span>
        <b-form-select
          v-model="localPerPage"
          @change="emitPerPageChange"
          :options="perPageOptions"
          class="d-inline-block"
          style="width: auto"
        ></b-form-select>
        <span> / 總數量: {{ totalRows }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: [Number, String],
      default: 1,
    },
    totalRows: {
      type: [Number, String],
      default: 0,
    },
    perPage: {
      type: [Number, String],
      default: 10,
    },
    perPageOptions: {
      type: Array,
      default: () => [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 50, value: 50 },
        { text: 100, value: 100 },
      ],
    },
  },
  data() {
    return {
      localPerPage: this.perPage,
      localCurrentPage: this.currentPage,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalRows / this.localPerPage);
    },
  },
  watch: {
    currentPage(newValue) {
      this.localCurrentPage = parseInt(newValue, 10);
    },
    perPage(newValue) {
      this.localPerPage = parseInt(newValue, 10);
    },
  },
  methods: {
    onChangePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.$emit("page-change", page);
      }
    },
    emitPerPageChange() {
      this.$emit("per-page-change", this.localPerPage);
    },
  },
};
</script>

<style scoped lang="scss"></style>
