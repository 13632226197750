import https from "./https"
import store from "@/store"

const organization = store.state.general.organization;

const form = {
  getForms(params) {
    return https.get(`admin/organizations/${organization.id}/forms`, { params })
  },
  createForm(params) {
    return https.post(`admin/organizations/${organization.id}/forms`, params)
  },
  updateForm(id, params) {
    return https.put(`admin/organizations/${organization.id}/forms/${id}`, params);
  },
  deleteForm(id) {
    return https.delete(`admin/organizations/${organization.id}/forms/${id}`);
  },
  getForm(id) {
    return https.get(`admin/organizations/${organization.id}/forms/${id}`);
  },
  getSubjects(id) {
    return https.get(`admin/organizations/${organization.id}/forms/${id}/subjects`);
  },
  createSubjects(id, subjects) {
    return https.post(`admin/organizations/${organization.id}/forms/${id}/subjects`, {
      subjects
    });
  },
  updateSubject(id, subjectId, subject) {
    return https.put(`admin/organizations/${organization.id}/forms/${id}/subjects/${subjectId}`, subject);
  },
  deleteSubject(id, subjectId) {
    return https.delete(`admin/organizations/${organization.id}/forms/${id}/subjects/${subjectId}`);
  },
  getResults(id, params) {
    return https.get(`admin/organizations/${organization.id}/forms/${id}/results`, { params });
  },
  exportResults(id, params = {}) {
    return https.get(`admin/organizations/${organization.id}/forms/${id}/export-results`, { params });
  },
  getQuestionnaireRegistrants(id, params = {}) {
    return https.get(`admin/organizations/${organization.id}/forms/${id}/questionnaire-registrants`, { params });
  },
}

export default form
